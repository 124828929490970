import React from "react"
import PropTypes from "prop-types"

import { pushToDataLayer, GtmDataPropTypes, GtmEventCategory } from "../../utils/handlers/gtmHandler"

import * as S from "./style"

const LUButton = ({
  autoSize,
  fontSize,
  height,
  minWidth,
  bold,
  colour,
  isOrangeCTA,
  hoverColour,
  uppercase,
  noWrap,
  iconUrl,
  margin,
  url,
  gtmData,
  isPulsating,
  handleClick,
  isHeaderCta,
  children
}) => {
  const handleButtonClick = () => {
    if (!gtmData) return

    const { eventType, action, label } = gtmData

    pushToDataLayer(eventType, action, label, GtmEventCategory.CLICK)
  }

  const props = {
    autoSize,
    fontSize,
    height,
    minWidth,
    bold,
    colour,
    hoverColour,
    uppercase,
    noWrap,
    isPulsating,
    isOrangeCTA,
    margin
  }
  if (url) {
    return (
      <S.Cta href={url} {...props} onClick={() => handleButtonClick() && handleClick()} isHeaderCta={isHeaderCta}>
        {iconUrl && <S.Icon src={iconUrl} alt="button icon" width="auto" height="25" />}
        <p>{children}</p>
      </S.Cta>
    )
  }
  return (
    <S.Button {...props} onClick={handleButtonClick && handleClick}>
      {iconUrl && <S.Icon src={iconUrl} alt="button icon" width="auto" height="25" />}
      <p>{children}</p>
    </S.Button>
  )
}

LUButton.propTypes = {
  autoSize: PropTypes.bool,
  fontSize: PropTypes.string,
  height: PropTypes.string,
  minWidth: PropTypes.string,
  bold: PropTypes.bool,
  colour: PropTypes.string,
  isOrangeCTA: PropTypes.bool,
  hoverColour: PropTypes.string,
  uppercase: PropTypes.bool,
  noWrap: PropTypes.bool,
  iconUrl: PropTypes.string,
  margin: PropTypes.string,
  url: PropTypes.string,
  isPulsating: PropTypes.bool,
  gtmData: GtmDataPropTypes,
  handleClick: PropTypes.func,
  isHeaderCta: PropTypes.bool,
  children: PropTypes.node.isRequired
}

LUButton.defaultProps = {
  autoSize: true,
  fontSize: "1.6rem",
  height: null,
  minWidth: null,
  bold: false,
  colour: null,
  isOrangeCTA: false,
  hoverColour: null,
  uppercase: false,
  noWrap: false,
  iconUrl: null,
  margin: null,
  url: null,
  isPulsating: false,
  gtmData: null,
  handleClick: null,
  isHeaderCta: null
}

export default LUButton
