import React from "react"
import PropTypes from "prop-types"

import { Spread, Gap } from "../../styles/constants"

import * as S from "./style"

const Column = ({ children, width, minWidth, maxWidth, horizontalSpread, verticalSpread, verticalChildGap }) => (
  <S.Column
    $width={width}
    $minWidth={minWidth}
    $maxWidth={maxWidth}
    $horizontalSpread={horizontalSpread}
    $verticalSpread={verticalSpread}
    $verticalChildGap={verticalChildGap}
  >
    {children}
  </S.Column>
)
export const defaultTypes = {
  children: PropTypes.node.isRequired,

  width: PropTypes.string,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  horizontalSpread: PropTypes.oneOf(Object.values(Spread)),
  verticalSpread: PropTypes.oneOf(Object.values(Spread)),
  verticalChildGap: PropTypes.oneOf(Object.values(Gap))
}

export const defaultProps = {
  width: "auto",
  minWidth: "none",
  maxWidth: "none",
  horizontalSpread: Spread.FLEX_START,
  verticalSpread: Spread.CENTER,
  verticalChildGap: Gap.NONE
}

Column.propTypes = defaultTypes
Column.defaultProps = defaultProps

export default Column
