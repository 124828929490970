import React from "react"
import PropTypes from "prop-types"

import { Padding, FlexDirection } from "../../styles/constants"

import * as S from "./style"

const TemplateSection = ({
  children,

  asElement,

  background,
  flexDirection,
  hideOverflow,
  hideOverflowX,
  hideOverflowY,
  topPadding,
  bottomPadding,
  minHeight,
  marginTop
}) => (
  <S.TemplateSection
    as={asElement}
    $hideOverflow={hideOverflow}
    $hideOverflowX={hideOverflowX}
    $hideOverflowY={hideOverflowY}
    $topPadding={topPadding}
    $bottomPadding={bottomPadding}
    $background={background}
    $minHeight={minHeight}
    $marginTop={marginTop}
  >
    <S.Inner $flexDirection={flexDirection}>{children}</S.Inner>
  </S.TemplateSection>
)

export const defaultTypes = {
  children: PropTypes.node.isRequired,

  asElement: PropTypes.string,

  background: PropTypes.string,
  flexDirection: PropTypes.oneOf(Object.values(FlexDirection)),
  hideOverflow: PropTypes.bool,
  hideOverflowX: PropTypes.bool,
  hideOverflowY: PropTypes.bool,
  topPadding: PropTypes.oneOf(Object.values(Padding)),
  bottomPadding: PropTypes.oneOf(Object.values(Padding)),
  minHeight: PropTypes.string,
  marginTop: PropTypes.string
}

export const defaultProps = {
  asElement: "section",

  background: "transparent",
  flexDirection: "row",
  hideOverflow: false,
  hideOverflowX: false,
  hideOverflowY: false,
  topPadding: Padding.LARGE,
  bottomPadding: Padding.LARGE,
  minHeight: "10vh",
  marginTop: "0"
}

TemplateSection.propTypes = defaultTypes
TemplateSection.defaultProps = defaultProps

export default TemplateSection
