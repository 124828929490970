/* eslint-disable no-underscore-dangle */
import styled, { css } from "styled-components"

export const CookieConsent = styled.div`
  position: fixed;
  inset: 0;
  z-index: 999;

  display: flex;
  flex-direction: column-reverse;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.75);

  padding: 10px;
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 450px;
  border-radius: 10px;
  margin: 10px auto;

  ${({
    theme: {
      components: {
        cookieConsent: { bgColour }
      }
    }
  }) => css`
    background-color: ${bgColour};
  `}
`

export const InfoPanel = styled.div`
  display: flex;
  flex-direction: column;

  padding: 16px;
  gap: 16px;

  ${({
    theme: {
      components: {
        cookieConsent: {
          cookieIcon: { bgColour, colour }
        }
      }
    }
  }) => css`
    svg {
      min-width: 70px;
      min-height: 70px;
    }

    svg > rect {
      fill: ${bgColour};
    }

    svg > path {
      fill: ${colour};
    }
  `}
`

export const Disclaimer = styled.div`
  display: flex;

  gap: 16px;
`

export const DisclaimerText = styled.div`
  display: inline-block;
  flex-grow: 1;

  font-size: 1.1em;
  text-align: center;
  ${props =>
    css`
      font-family: ${props.theme.fonts.leoUniverse};
    `}

  ${({
    theme: {
      components: {
        cookieConsent: {
          disclaimer: { colour }
        }
      }
    }
  }) =>
    css`
      ${colour && `color: ${colour ?? "#fff"}`};
    `}

  a {
    text-decoration: none;
  }

  button {
    font-size: 1em;
    font-weight: 600;
  }

  h4 {
    font-size: 1.6em;
    margin: 20px 0;
  }

  line-height: 1.2;
`

export const PolicyModalButton = styled.button`
  ${({
    theme: {
      components: {
        cookieConsent: {
          disclaimer: {
            fontSize,
            link: { colour }
          }
        }
      }
    }
  }) =>
    css`
      ${colour && `color: ${colour ?? "#fff"}`};
      ${fontSize && `font-size: ${fontSize}`};
    `}

  background: none;
  border: none;
  cursor: pointer;

  margin-right: 20px;
  text-decoration: none;
  display: inline-block;

  padding: 0;
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: column;

  flex-grow: 1;

  @media (min-width: 600px) {
    display: flex;
  }

  gap: 16px;

  ${({ $isManagementPanelOpen }) =>
    $isManagementPanelOpen &&
    css`
      display: none;
    `}
`

const getButton = key => css`
  ${({
    theme: {
      components: {
        cookieConsent: {
          buttons: {
            [key]: { colour, bgColour, hover, borderRadius, borderColour, maxHeight, padding, boxShadow, width, textTransform }
          }
        }
      }
    }
  }) =>
    css`
      ${colour && `color: ${colour}`};
      ${bgColour && `background-color: ${bgColour}`};
      ${borderRadius && `border-radius: ${borderRadius}`};
      ${borderColour ? `border: 1px solid ${borderColour}` : "border: none"};
      ${maxHeight && `max-height: ${maxHeight}`};
      ${padding && `padding: ${padding}`};
      ${boxShadow && `box-shadow: ${boxShadow}`};
      ${width && `width: ${width}`};
      ${textTransform && `text-transform: ${textTransform}`};

      :hover {
        ${hover?.bgColour && `background-color: ${hover.bgColour}`};
      }
    `}

  height: 48px;
  font-weight: 600;
  cursor: pointer;
  font-size: ${({ theme }) => theme.components.cookieConsent.buttons?.fontSize};
`

export const ManageButton = styled.button`
  ${getButton("manage")}
`

export const ConfirmButton = styled.button`
  ${getButton("confirm")}
`

export const AllowButton = styled.button`
  ${getButton("allowAll")}
`

export const AcceptButton = styled.button`
  ${getButton("accept")}
`

export const ManagementButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  gap: 8px;
  margin-top: 16px;

  ${ConfirmButton} {
    width: 100%;
  }

  ${AllowButton} {
    width: 100%;
  }

  @media (min-width: 600px) {
    display: none;
  }
`

export const ManagePanel = styled.div`
  display: flex;
  flex-direction: column;

  ${({
    theme: {
      components: {
        cookieConsent: { borderColour }
      }
    }
  }) => css`
    border-top: ${borderColour} 1px solid;
  `}

  padding: 16px 0;
`

export const Toggles = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  gap: 12px;
`
