import PropTypes from "prop-types"
import { useState, useEffect } from "react"
import { getMarketLicense } from "../../helpers"

export const GtmEventType = {
  BUTTON_CLICK: "buttonClick",
  POPUP: "popUp"
}

export const GtmAction = {
  GAMES_EXPLORE_IMG_CLICK: "Click on explore games img",
  GAMES_EXPLORE_CTA_CLICK: "Click on explore games CTA",
  THREE_STEP_CTA_CLICK: "Click on CTA below 1-2-3 steps",
  THREE_STEP_CARD_CLICK: "1-2-3 steps",
  FOOTER_LINK_CLICK: "Footer link click",
  ANDROID_APP_LINK_CLICK: "Click on Download Google Play Store",
  IOS_APP_LINK_CLICK: "Click on Download Apple Store",
  MAIN_CTA_CLICK: "Click on main CTA",
  SPINNING_WHEEL_CTA_CLICK: "Spinning wheel CTA",
  STICKY_HEADER_CTA_CLICK: "Click on sticky header CTA",
  BANNER_CLICK: "Click on banner",
  BANNER_LOGIN_CLICK: "Login below CTA",
  NAVBAR_LOGO_CLICK: "Click on Logo",
  LEGAL_LINE_LINK_CLICK: "Click on legal line link",
  SUPPORT_PHONE_CLICK: "Phone Click",
  SUPPORT_EMAIL_CLICK: "Mail Click",
  ARTICLE_CLICK: "Click on article link",
  SLOTS_GAME_CLICK: "Overlap section game icons",
  SLOTS_CTA_CLICK: "Overlap section CTA",
  COPY_SECTION: "Copy section",
  PAYMENT_PROVIDER_BAR_CLICK: "payment methods",
  BANNERFLOW_DYNAMIC_BANNER_CLICK: "Click on bannerflow dynamic banner",
  MULTIOFFER_CTA_CLICK: "Click on multioffer CTA"
}

export const GtmEventCategory = {
  CLICK: "Click"
}

export const GtmDataPropTypes = PropTypes.shape({
  eventType: PropTypes.oneOf(Object.values(GtmEventType)).isRequired,
  action: PropTypes.oneOf(Object.values(GtmAction)).isRequired,
  label: PropTypes.string.isRequired,
  category: PropTypes.oneOf(Object.values(GtmEventCategory))
})

export const pushToDataLayer = (eventType, action, label, category) => {
  const data = {
    event: eventType,
    eventAction: action,
    eventLabel: label || ""
  }

  if (category) {
    data.eventCategory = category
  }

  dataLayer.push(data)
}

export const pushCountryToDataLayer = (pathname, title) => {
  // RETRIEVE MARKET FROM URL
  const country = pathname.split("/")[1].replace("-", "_").toUpperCase()

  window.dataLayer = window.dataLayer || []

  const slug = pathname.substring(pathname.indexOf("/", 1))

  const marketLicense = getMarketLicense(country)

  // Temporary guard for UK.
  if (window.location.hostname.indexOf(".co.uk") > -1) {
    dataLayer.push({ country, license: marketLicense })

    return
  }

  dataLayer.push({
    market: country === "EN_FI" ? "FI" : country, // to check if we can remove en-fi pages
    countryId: country === "EN_FI" ? "FI" : country.split("_")[0], // to check if we can remove en-fi pages
    license: marketLicense,
    event: "spa_navigation",
    vertical: "promo",
    logged_in: "false",
    page_path: slug,
    page_title: title
  })
}

export function pushConsentToDataLayer() {
  // eslint-disable-next-line prefer-rest-params
  dataLayer.push(arguments)
}

export const useCookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false)
  const [shouldLoadGtm, setShouldLoadGtm] = useState(false)

  const createExpiryDate = () => {
    const expiryDate = new Date()
    expiryDate.setDate(expiryDate.getDate() + 183)
    return expiryDate
  }

  const accept = consent => {
    const gtagConsentMode = {
      security_storage: consent.necessary ? "granted" : "denied",
      functionality_storage: consent.functional ? "granted" : "denied",
      ad_storage: consent.marketing ? "granted" : "denied",
      analytics_storage: consent.analytics ? "granted" : "denied",
      personalization_storage: consent.necessary ? "granted" : "denied",
      ad_user_data: consent.marketing ? "granted" : "denied",
      ad_personalization: consent.marketing ? "granted" : "denied"
    }

    const lsConsentMode = {
      ...gtagConsentMode,
      expiry: createExpiryDate()
    }

    pushConsentToDataLayer("consent", "update", gtagConsentMode)

    localStorage.setItem("consentMode", JSON.stringify(lsConsentMode))

    setShowConsent(false)
    setShouldLoadGtm(true)
  }

  useEffect(() => {
    const consentMode = JSON.parse(localStorage.getItem("consentMode"))

    if (consentMode) {
      const expiryDate = Date.parse(consentMode.expiry)

      if (Date.now() < expiryDate) {
        setShouldLoadGtm(true)
        return
      }
    }
    setShowConsent(true)
  }, [])

  return {
    showConsent,
    shouldLoadGtm,
    accept
  }
}
