import React from "react"
import PropTypes from "prop-types"

import * as S from "./style"

const LinkList = ({ links, bold, handleClick }) =>
  links.map(({ title, url }, index) => {
    if (title) {
      const key = `link-${index}`
      return (
        <S.Link key={`link-${key}`} href={url} $bold={bold} {...(handleClick !== undefined && { onClick: () => handleClick(title) })}>
          {title}
        </S.Link>
      )
    }
    return null
  })

LinkList.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired,
  bold: PropTypes.bool,
  handleClick: PropTypes.func.isRequired
}

LinkList.defaultProps = {
  bold: false
}

export default LinkList
