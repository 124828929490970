import styled, { css } from "styled-components"

export const ToggleInput = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 16px;

  border: none;
  background: none;

  ${({ $disabled }) =>
    $disabled
      ? css`
          opacity: 0.5;
        `
      : css`
          cursor: pointer;
        `}
`

export const Toggle = styled.div`
  position: relative;

  min-width: 34px;
  min-height: 14px;

  border-radius: 8px;
  margin: 0;

  ${({ $isOn }) =>
    $isOn
      ? css`
          background-color: #1daa63;
        `
      : css`
          background-color: #e2e2e2;
        `}

  transition: background-color 0.2s ease-in-out;
`

export const Pin = styled.div`
  position: absolute;

  width: 20px;
  height: 20px;

  top: -3px;

  ${({ $isOn }) =>
    $isOn
      ? css`
          transform: translateX(100%);
          background-color: #fff;
        `
      : css`
          transform: translateX(calc(0% - 6px));
          background-color: #fff;
        `}

  transition: transform 0.2s ease-in-out;

  box-shadow: rgba(0, 0, 0, 0.25) 1px 1px 3px 1px;

  border-radius: 100%;
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`

export const Title = styled.p`
  letter-spacing: 0.5px;
  text-align: left;
  margin: 3px 0;
  font-weight: 600;
  font-size: 1.1em;

  ${({ $colour }) =>
    css`
      color: ${$colour};
    `}
`

export const Body = styled.p`
  ${({ $colour }) =>
    css`
      color: ${$colour};
    `}

  text-align: left;
  margin: 3px 0;
  font-size: 0.9em;
`
