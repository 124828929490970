/* eslint-disable no-underscore-dangle */
import ReactMarkdown from "react-markdown"
import styled, { css } from "styled-components"

import { ManageButton } from "../LUCookieConsent/style"

export const Modal = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.45);

  top: 0px;
  left: 0px;
  bottom: 0;
  right: 0;

  padding: 10px;

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`

export const Inner = styled.div`
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  top: 50%;
  transform: translate(0, -50%);
  max-width: 450px;
  max-height: 80vh;
  border-radius: 10px;
`

export const PolicyWrap = styled.div`
  max-height: 95%;

  overflow-y: auto;
`

export const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  padding: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`
export const CloseBtn = styled(ManageButton)`
  display: block;
  background: none;
  border: none;
`

const subListItem = css`
  li {
    font-size: 1em;
    list-style: inside;
    list-style-type: circle;
  }
`

export const Policy = styled(ReactMarkdown)`
  padding: 30px;
  word-wrap: break-word;

  * {
    font-size: 1em;
    line-height: 1.65em;
  }

  h1,
  h2 {
    font-size: 1.9em;
    font-weight: bold;
    margin: 20px 0;
  }

  h3 {
    font-size: 1.8em;
    font-weight: bold;
    margin: 20px 0;
  }

  h4 {
    font-size: 1.4em;
    font-weight: bold;
    margin: 20px 0;
  }

  p strong {
    font-weight: 700;
  }

  ol,
  ul {
    padding: 10px 30px;
  }

  ul {
    li {
      list-style-type: disc;

      ${subListItem};

      p {
        margin: 0;
      }
    }
  }

  ol {
    li {
      list-style-type: decimal;

      ${subListItem};

      p {
        margin: 0;
      }
    }
  }

  ol[type="a"] {
    li {
      list-style-type: lower-alpha;
    }
  }

  a {
    text-decoration: underline;
    ${({
      theme: {
        components: {
          cookieConsent: {
            disclaimer: {
              link: { colour }
            }
          }
        }
      }
    }) =>
      css`
        ${colour && `color: ${colour ?? "#fff"}`};
      `}
  }
`
