import React from "react"
import PropTypes from "prop-types"

import * as S from "./style"

import LUFixedImage from "../LUFixedImage"
import { GATSBY_IMAGE_FIXED_PROPTYPE } from "../../helpers/constants"

const LUAwards = ({ title, awardImages }) => {
  return (
    <S.AwardsContainer>
      <S.Title>{title}</S.Title>
      <S.Awards>
        {awardImages.map(({ fixed, title: awardsTitle, description }, index) => (
          <S.ImageContainer key={index}>
            <LUFixedImage fixed={fixed} title={awardsTitle} alt={description} />
            {description && <p>{description}</p>}
          </S.ImageContainer>
        ))}
      </S.Awards>
    </S.AwardsContainer>
  )
}

LUAwards.propTypes = {
  awardImages: PropTypes.arrayOf(
    PropTypes.shape({
      fixed: GATSBY_IMAGE_FIXED_PROPTYPE,
      title: PropTypes.string,
      description: PropTypes.string
    })
  ).isRequired,
  title: PropTypes.string.isRequired
}

export default LUAwards
