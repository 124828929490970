import styled from "styled-components"
import { LazyLoadImage } from "react-lazy-load-image-component"

import { MediaQuery } from "../../styles/constants"

export const ImageLink = styled.a`
  text-align: center;
  flex: ${props => props.$flex};

  p {
    color: #fff;
    margin: 5px;
  }
`

export const Image = styled(LazyLoadImage)`
  box-sizing: border-box;

  max-height: ${props => props.$desktopImageHeight};
  max-width: 135px;

  padding: 5px;

  @media (max-width: ${MediaQuery.RESOLUTION_768}px) {
    max-height: ${props => props.$mobileImageHeight};
  }
`
