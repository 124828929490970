import styled, { css } from "styled-components"

import { Gap } from "../../styles/constants"

export const Column = styled.div`
  flex: 1;
  ${props => css`
    display: flex;
    flex-direction: column;
    justify-content: ${props.$verticalSpread};
    align-items: ${props.$horizontalSpread};

    width: ${props.$width};
    min-width: ${props.$minWidth};
    max-width: ${props.$maxWidth};

    ${props.$verticalChildGap &&
    props.$verticalChildGap !== Gap.NONE &&
    css`
      > *:not(:last-child) {
        margin-bottom: ${props.$verticalChildGap};
      }
    `}

    box-sizing: border-box;
  `};
`
