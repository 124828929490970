import PropTypes from "prop-types"
import React, { useContext, useState } from "react"

import { Context as TemplateContext } from "../Layout/index"
import * as S from "./style"
import Modal from "../Modal"
import Toggle from "../Toggle"

const LUCookieConsent = ({
  disclaimerTitle,
  disclaimer,
  cookiePolicyLink,
  acceptButtonText,
  allowAllButtonText,
  confirmButtonText,
  manageButtonText,
  necessary,
  functional,
  analytics,
  marketing,
  cookiePolicy
}) => {
  const {
    cookieConsent: { showConsent, accept }
  } = useContext(TemplateContext)

  const [isManagementPanelOpen, setIsManagementPanelOpen] = useState(false)
  const [consent, setConsent] = useState({
    necessary: "granted",
    functional: "granted",
    analytics: "granted",
    marketing: "granted"
  })
  const [showPolicyDialog, setShowPolicyDialog] = useState(false)

  /*
    We are doing a conditional render inside this component instead of the templates,
    in order to prevent a re-render of the whole template when the user clicks accepts.
  */
  if (!showConsent) return null

  const handleManageButtonClick = () => setIsManagementPanelOpen(true)
  const handleConfirmClick = () => accept(consent)
  const handleAllowAllClick = () =>
    accept({
      necessary: "granted",
      functional: "granted",
      analytics: "granted",
      marketing: "granted"
    })
  const handleChange = (key, value) => setConsent({ ...consent, [key]: value })

  const handleShowModal = event => {
    event.preventDefault()

    setShowPolicyDialog(true)
    document.body.style.overflow = "hidden"
  }

  const handleCloseDialog = () => {
    setShowPolicyDialog(false)
    document.body.style.overflow = "unset"
  }

  return (
    <S.CookieConsent>
      <S.Inner>
        <S.InfoPanel>
          <S.Disclaimer>
            <S.DisclaimerText>
              <h4>{disclaimerTitle}</h4>
              {disclaimer}
              <S.PolicyModalButton onClick={handleShowModal}>{cookiePolicyLink}</S.PolicyModalButton>
            </S.DisclaimerText>
          </S.Disclaimer>
          {isManagementPanelOpen && (
            <S.ManagePanel>
              <S.Toggles>
                <Toggle
                  key="necessary"
                  onChange={value => handleChange("necessary", value)}
                  isOn={consent.necessary}
                  disabled
                  title={necessary.title}
                  body={necessary.description}
                />
                <Toggle
                  key="functional"
                  onChange={value => handleChange("functional", value)}
                  isOn={consent.functional}
                  title={functional.title}
                  body={functional.description}
                />
                <Toggle
                  key="analytics"
                  onChange={value => handleChange("analytics", value)}
                  isOn={consent.analytics}
                  title={analytics.title}
                  body={analytics.description}
                />
                <Toggle
                  key="marketing"
                  onChange={value => handleChange("marketing", value)}
                  isOn={consent.marketing}
                  title={marketing.title}
                  body={marketing.description}
                />
              </S.Toggles>
              <S.ManagementButtons>
                <S.ConfirmButton onClick={handleConfirmClick}>{confirmButtonText}</S.ConfirmButton>
                <S.AllowButton onClick={handleAllowAllClick}>{isManagementPanelOpen ? allowAllButtonText : acceptButtonText}</S.AllowButton>
              </S.ManagementButtons>
            </S.ManagePanel>
          )}
          <S.Buttons $isManagementPanelOpen={isManagementPanelOpen}>
            {isManagementPanelOpen ? (
              <>
                <S.ConfirmButton onClick={handleConfirmClick}>{confirmButtonText}</S.ConfirmButton>
                <S.AllowButton onClick={handleAllowAllClick}>{isManagementPanelOpen ? allowAllButtonText : acceptButtonText}</S.AllowButton>
              </>
            ) : (
              <>
                <S.AcceptButton onClick={handleAllowAllClick}>{acceptButtonText}</S.AcceptButton>
                <S.ManageButton onClick={handleManageButtonClick}>{manageButtonText}</S.ManageButton>
              </>
            )}
          </S.Buttons>
        </S.InfoPanel>
      </S.Inner>
      <Modal policy={cookiePolicy} isOpen={showPolicyDialog} onClose={handleCloseDialog}>
        <S.AcceptButton onClick={handleAllowAllClick}>{acceptButtonText}</S.AcceptButton>
      </Modal>
    </S.CookieConsent>
  )
}

LUCookieConsent.propTypes = {
  disclaimerTitle: PropTypes.string.isRequired,
  disclaimer: PropTypes.string.isRequired,
  acceptButtonText: PropTypes.string.isRequired,
  allowAllButtonText: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  manageButtonText: PropTypes.string.isRequired,
  cookiePolicyLink: PropTypes.string.isRequired,
  necessary: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  functional: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  analytics: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  marketing: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  cookiePolicy: PropTypes.string.isRequired
}

export default LUCookieConsent
