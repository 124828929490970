import React from "react"
import PropTypes from "prop-types"

import LinkList from "../LinkList"
import TemplateSection from "../TemplateSection"

import { Padding } from "../../styles/constants"

import { pushToDataLayer, GtmEventType, GtmAction } from "../../utils/handlers/gtmHandler"

import * as S from "./style"

const handleLinkClick = title => pushToDataLayer(GtmEventType.BUTTON_CLICK, GtmAction.FOOTER_LINK_CLICK, title)

const FooterLinks = ({ links, backgroundColour, bold }) => {
  return (
    <TemplateSection background={backgroundColour} topPadding={Padding.SMALL} bottomPadding={Padding.SMALL}>
      <S.LinkContainer>
        <LinkList links={links} bold={bold} handleClick={handleLinkClick} />
      </S.LinkContainer>
    </TemplateSection>
  )
}

FooterLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired,
  backgroundColour: PropTypes.string,
  bold: PropTypes.bool
}

FooterLinks.defaultProps = {
  backgroundColour: "transparent",
  bold: false
}

export default FooterLinks
