import React from "react"
import PropTypes from "prop-types"

import LUFixedImage from "../LUFixedImage"
import { GATSBY_IMAGE_FIXED_PROPTYPE } from "../../helpers/constants"
import { pushToDataLayer, GtmEventType, GtmAction, GtmEventCategory } from "../../utils/handlers/gtmHandler"

import * as S from "./style"

const LUPaymentProviderBar = ({ images, url, isStepsSectionVisible }) => {
  const handleClick = () => pushToDataLayer(GtmEventType.BUTTON_CLICK, GtmAction.PAYMENT_PROVIDER_BAR_CLICK, null, GtmEventCategory.CLICK)

  return (
    <S.PaymentProviderBar isStepsSectionVisible={isStepsSectionVisible}>
      <S.Inner href={url} onClick={handleClick}>
        <S.Images>
          {images.map(({ fixed, title, description }, index) => (
            <LUFixedImage key={index} fixed={fixed} title={title} alt={description} />
          ))}
        </S.Images>
      </S.Inner>
    </S.PaymentProviderBar>
  )
}

LUPaymentProviderBar.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      fixed: GATSBY_IMAGE_FIXED_PROPTYPE,
      title: PropTypes.string,
      description: PropTypes.string
    })
  ).isRequired,
  url: PropTypes.string.isRequired,
  isStepsSectionVisible: PropTypes.bool
}

LUPaymentProviderBar.defaultProps = {
  isStepsSectionVisible: true
}

export default LUPaymentProviderBar
