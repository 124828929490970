import styled, { css } from "styled-components"
import ReactMarkdown from "react-markdown"
import { palette } from "../../styles"

export const SponsorsOfText = styled.p`
  color: #cfcfcf;

  font-family: ${props => props.theme.fonts.primary};
`

export const Divider = styled.div`
  height: 1px;
  width: 100%;

  background-color: #3a3a3a;

  margin-bottom: 30px;
`

export const Markdown = styled(ReactMarkdown)`
  p {
    ${props => css`
      color: #cfcfcf;
      font-family: ${props.theme.fonts.primary};
      font-size: 0.9em;
      line-height: 1.5em;
    `}

    margin-bottom: 15px;
  }

  a {
    color: ${palette.outrageousOrange};

    :visited {
      color: ${palette.mojo};
    }
  }

  :after {
    content: url(${props => props.$eighteenPlusIcon});
  }
`

export const Logo = styled.div`
  img {
    width: auto;
    height: 50px;

    @media (max-width: 768px) {
      width: 100%;
      height: auto;
    }
  }
`
