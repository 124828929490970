export const MediaQuery = {
  RESOLUTION_512: 512,
  RESOLUTION_678: 678,
  RESOLUTION_768: 768,
  RESOLUTION_769: 769,
  RESOLUTION_876: 876,
  RESOLUTION_1024: 1024
}

export const AnimationPath = {
  CUBIC_SMOOTH: "cubic-bezier(0.48, 0, 0.12, 1)",
  LINEAR: "linear",
  EASE_IN: "ease-in",
  EASE_OUT: "ease-out",
  EASE_IN_OUT: "ease-in-out"
}

export const Spread = {
  CENTER: "center",
  FLEX_START: "flex-start",
  FLEX_END: "flex-end",
  SPACE_AROUND: "space-around",
  SPACE_EVENLY: "space-evenly",
  SPACE_BETWEEN: "space-between"
}

export const FlexDirection = {
  ROW: "row",
  COLUMN: "column"
}

export const Gap = {
  NONE: "0px",
  SMALL: "15px",
  MEDIUM: "25px",
  LARGE: "35px"
}

export const Padding = {
  NONE: "0px",
  SMALL: "20px",
  MEDIUM: "40px",
  LARGE: "60px",
  EXTRALARGE: "120px"
}

export const Size = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large"
}

export const TextTransform = {
  NONE: "none",
  UPPERCASE: "uppercase",
  LOWERCASE: "lowercase",
  CAPITALIZE: "capitalize"
}
