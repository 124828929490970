import PropTypes from "prop-types"
import React from "react"
import rehypeRaw from "rehype-raw"

import * as S from "./style"

const Modal = ({ policy, isOpen, onClose, children }) => {
  const parts = typeof window !== "undefined" && new URL(window.location).hostname.split(".")

  const domainName = parts[1]

  const markdownLink = ({ href, cchildren }) => {
    return href.includes(domainName) ? ( // add params?
      <a href={href} target="_blank" rel="noreferrer">
        {cchildren}
      </a>
    ) : (
      <a href={href} target="_blank" rel="noreferrer">
        {cchildren}
      </a>
    )
  }

  return (
    <S.Modal $isOpen={isOpen}>
      <S.Inner>
        <S.PolicyWrap>
          <S.Policy
            components={{
              a: markdownLink
            }}
            rehypePlugins={[rehypeRaw]}
          >
            {policy}
          </S.Policy>
        </S.PolicyWrap>
        <S.BtnContainer>
          {children}
          <S.CloseBtn onClick={onClose}>Close</S.CloseBtn>
        </S.BtnContainer>
      </S.Inner>
    </S.Modal>
  )
}

Modal.propTypes = {
  policy: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default Modal
