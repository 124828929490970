import styled from "styled-components"

export const LinkContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  width: 100%;
`
