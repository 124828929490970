import styled, { css } from "styled-components"

export const Link = styled.a`
  box-sizing: border-box;

  min-width: 150px;
  padding: 15px;

  color: ${props => props.theme.colours.text.primary};
  text-decoration: none;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 1em;
  text-align: center;

  ${props =>
    props.$bold &&
    css`
      font-weight: bold;
    `}
  :hover {
    text-decoration: underline;
  }
`
