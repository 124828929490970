import styled, { css } from "styled-components"

import { Gap, MediaQuery } from "../../styles/constants"

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap; /* TODO: Make dynamic */
  justify-content: ${props => props.$horizontalSpread};
  align-items: center;

  box-sizing: border-box;

  width: 100%;
  max-width: ${props => props.$maxWidth};
  min-height: ${props => props.$minHeight};

  ${props =>
    props.$padding !== undefined &&
    css`
      padding: ${props.$padding};
    `}

  background-color: ${props => props.$backgroundColour};

  ${props =>
    props.$horizontalChildGap &&
    props.$horizontalChildGap !== Gap.NONE &&
    css`
      > *:not(:last-child) {
        margin-right: ${props.$horizontalChildGap};
      }
      margin-bottom: 0;
    `}

  ${props =>
    props.$fill &&
    css`
      > * {
        width: 100%;
      }
    `}
  @media (max-width: ${MediaQuery.RESOLUTION_512}px) {
    ${props =>
      props.$isScrolling &&
      css`
        overflow-x: scroll;
        white-space: nowrap;
        flex-wrap: nowrap;
        padding-left: 5%;
        padding-right: 5%;
        justify-content: space-between;
        padding: 20px 0;
        picture {
          margin: 0 15px;
        }
      `}
  }
`
