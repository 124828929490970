import styled, { css } from "styled-components"
import { Res } from "../../utils/constants"
import { hideScrollbars } from "../../styles/common"

export const PaymentProviderBar = styled.div`
  position: relative;

  z-index: 1;

  display: flex;
  justify-content: center;

  min-height: 65px;
  height: 10vw;
  max-height: 110px;

  margin: 30px 0;

  @media (min-width: ${Res._768}px) {
    margin: 35px 0;
    height: 5vw;
  }
  ${props =>
    !props.isStepsSectionVisible &&
    css`
      margin: 60p 0;

      @media (min-width: ${Res._1024}px) {
        margin: 60px 80px;
      }
    `}
`

export const Inner = styled.a`
  ${hideScrollbars};

  position: relative;
  overflow-x: scroll;

  max-width: 1075px;
  height: 100%;

  margin: 0 ${props => props.theme.boxLayout.margin.value};

  white-space: nowrap;

  border-radius: 12px;

  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);

  transition: box-shadow 0.25s ease-in-out;

  :hover {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  }

  :after {
    content: "";

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    z-index: 1;

    width: 15%;

    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), white 85%);

    border-radius: 12px;

    pointer-events: none;
  }

  @media (min-width: ${Res._768}px) {
    ::after {
      content: none;
    }
  }
`

export const Images = styled.div`
  ${hideScrollbars};

  position: relative;
  overflow-x: scroll;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;

  height: 100%;

  padding: 0 5px;

  background-color: #fff;

  border-radius: 12px;

  picture {
    margin: 0 5px;

    :last-child {
      padding-right: 30px;
    }
  }

  @media (min-width: ${Res._768}px) {
    overflow-x: auto;

    padding: 0 25px;

    picture {
      margin: 0 15px;
    }
  }
`
