import PropTypes from "prop-types"
import React, { useState } from "react"

import * as S from "./style"

const Toggle = ({ title, body, isOn = false, disabled, onChange }) => {
  const [on, setOn] = useState(isOn)

  const handleChange = () => {
    if (disabled) return

    const newState = !on
    setOn(newState)
    onChange(newState)
  }

  return (
    <S.ToggleInput onClick={handleChange} $disabled={disabled}>
      <S.Text>
        <S.Title>{title}</S.Title>
        <S.Body>{body}</S.Body>
      </S.Text>
      <S.Toggle $isOn={on}>
        <S.Pin $isOn={on} />
      </S.Toggle>
    </S.ToggleInput>
  )
}

Toggle.propTypes = {
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  isOn: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

Toggle.defaultProps = {
  isOn: false,
  disabled: false
}

export default Toggle
