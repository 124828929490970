import React from "react"
import PropTypes from "prop-types"

import { pushToDataLayer, GtmEventType, GtmAction } from "../../utils/handlers/gtmHandler"

import * as S from "./style"

const Logo = ({ isAffiliateLogo, url, imageUrl, primaryBgColour, shouldResize, shrink, wvWidth, wvHeight, isNewMobileTemplate }) => {
  const handleClick = () => pushToDataLayer(GtmEventType.BUTTON_CLICK, GtmAction.NAVBAR_LOGO_CLICK, "Navbar Logo")

  const altText = isAffiliateLogo ? "affiliate logo" : "logo"

  return (
    <S.Logo
      href={url}
      onClick={handleClick}
      primaryBgColour={primaryBgColour}
      shouldResize={shouldResize}
      shrink={shrink}
      isAffiliateLogo={isAffiliateLogo}
      isNewMobileTemplate={isNewMobileTemplate}
    >
      <img src={imageUrl} alt={altText} width={wvWidth} height={wvHeight} />
    </S.Logo>
  )
}

Logo.propTypes = {
  isAffiliateLogo: PropTypes.bool,
  url: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  primaryBgColour: PropTypes.string,
  shouldResize: PropTypes.bool,
  wvWidth: PropTypes.string.isRequired,
  wvHeight: PropTypes.string.isRequired,
  shrink: PropTypes.bool,
  isNewMobileTemplate: PropTypes.bool
}

Logo.defaultProps = {
  isAffiliateLogo: false,
  primaryBgColour: "transparent",
  shouldResize: false,
  shrink: false,
  isNewMobileTemplate: false
}

export default Logo
