import styled, { css, keyframes } from "styled-components"
import { convertHexToRgb } from "../../helpers"

const getButtonShadow = (r, g, b, o = 1) => `rgba(${r}, ${g}, ${b}, ${o})`

const getPulseAnimation = hexColour => {
  const { r, g, b } = convertHexToRgb(hexColour)

  return keyframes`
    0% {
      box-shadow: 0 0 0 0px ${getButtonShadow(r, g, b, 0.3)}, 0 0 0 0px ${getButtonShadow(r, g, b, 0.2)};
      transform: scale(1);
    }
    18% {
      box-shadow: 0 0 0 0px ${getButtonShadow(r, g, b, 0.3)}, 0 0 0 0px ${getButtonShadow(r, g, b, 0.2)};
    }
    35% {
      transform: scale(1.02);
    }
    76% {
      transform: scale(0.98);
    }
    100% {
      box-shadow: 0 0 0 16px ${getButtonShadow(r, g, b, 0)}, 0 0 0 27px ${getButtonShadow(r, g, b, 0)};
      transform: scale(1);
    }
  `
}

export const ButtonStyling = css`
  position: relative;

  display: inline-flex;
  justify-content: center;
  align-content: center;
  text-align: center;

  min-height: ${props => (props.height ? props.height : "48px")};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
  max-width: 285px;

  box-sizing: border-box;
  ${({ margin }) => margin && `margin: ${margin};`};
  padding: ${props => (props.isHeaderCta ? "5px 8px" : "10px 19px")};

  ${({ isOrangeCTA, theme, colour }) => (isOrangeCTA ? `background-color: ${theme.colours.primary};` : `background-color: ${colour || "#1daa63"};`)}

  color: #fff;

  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.fontSize};
  font-weight: ${props => (props.bold ? "bold" : "regular")};
  text-transform: uppercase;

  border: none;
  border-radius: 6px;

  text-decoration: none;

  cursor: pointer;

  transition: background-color 0.1s ease-in-out;

  ${({ isPulsating, isOrangeCTA, colour, theme }) =>
    isPulsating &&
    css`
      animation: ${getPulseAnimation(isOrangeCTA ? theme.colours.primary : colour || "#1daa63")} 1.6s ease-out infinite;
    `}

  p {
    margin: auto 0;

    ${props => props.uppercase && "text-transform: uppercase"};
    ${props => props.noWrap && "white-space: nowrap"};
  }

  ${props =>
    props.hoverColour &&
    css`
      :hover {
        background-color: ${props.hoverColour};
      }
    `};
  @media (min-width: 512px) {
    padding: ${props => (props.isHeaderCta ? "5px 8px" : "10px 40px")};
    max-width: 300px;
  }
`

export const Icon = styled.img`
  margin: auto 15px auto 5px;
  @media (max-width: 360px) {
    margin: auto 10px auto 0;
    max-height: 16px;
  }
  @media (min-width: 512px) {
    margin: auto 15px auto 0;
  }
`

export const Button = styled.button`
  ${ButtonStyling}
`
export const Cta = styled.a`
  ${ButtonStyling}
  ${props =>
    props.isHeaderCta &&
    css`
      margin: 0 0 0 10px !important;

      @media (max-width: 320px) {
        display: none;
      }
    `}
`
