import React from "react"
import PropTypes from "prop-types"

import Picture from "../Picture"

import * as S from "./style"

const ImageList = ({ images, desktopImageHeight, mobileImageHeight, displayTitle, flex }) => (
  <>
    {images.map(({ title, url, link }, ind) => {
      const key = url + ind
      const image = (
        <Picture src={url} useWebp key={key}>
          <S.Image src={url} alt={title} $desktopImageHeight={desktopImageHeight} $mobileImageHeight={mobileImageHeight} />
        </Picture>
      )

      return link !== undefined ? (
        <S.ImageLink key={url} href={link || undefined} target="_blank" rel="noreferrer" $flex={flex}>
          {image}
          {displayTitle && <p>{title}</p>}
        </S.ImageLink>
      ) : (
        image
      )
    })}
  </>
)

export const defaultTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string.isRequired
    })
  ).isRequired,

  desktopImageHeight: PropTypes.string,
  mobileImageHeight: PropTypes.string,
  displayTitle: PropTypes.bool,
  flex: PropTypes.string
}

export const defaultProps = {
  desktopImageHeight: "35px",
  mobileImageHeight: "30px",
  displayTitle: false,
  flex: null
}

ImageList.propTypes = defaultTypes
ImageList.defaultProps = defaultProps

export default ImageList
