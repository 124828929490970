import React from "react"
import * as PropTypes from "prop-types"
import Media from "react-media"
import rehypeRaw from "rehype-raw"
import TemplateSection from "../TemplateSection"
import Column from "../Column"
import Row from "../Row"

import ImageList from "../ImageList"

import Picture from "../Picture"

import { MediaQuery, Spread, Gap } from "../../styles/constants"

import eighteenPlusJapanIcon from "../../assets/icon-18plus-japan.png"
import eighteenPlusSpainIcon from "../../assets/icon-18plus-spain.png"
import eighteenPlusIcon from "../../assets/icon-18plus.png"
import nineteenPlusIcon from "../../assets/19plus.png"

import * as S from "./style"

const Footer = ({ language, sponsorsText, sponsorsLogos, licensesLogos, footerDisclaimer, singleRgLogo, responsibleGamingLogos, background }) => {
  const get18PlusIcon = () => {
    if (language === "jp") {
      return eighteenPlusJapanIcon
    }
    if (language === "es") {
      return eighteenPlusSpainIcon
    }
    if (language === "ca-on") {
      return nineteenPlusIcon
    }

    return eighteenPlusIcon
  }

  return (
    <TemplateSection asElement="footer" background={background} topPadding="40px">
      <Column horizontalSpread={Spread.CENTER} verticalChildGap={Gap.MEDIUM}>
        {sponsorsText && <S.SponsorsOfText>{sponsorsText}</S.SponsorsOfText>}
        {sponsorsLogos && (
          <Row horizontalSpread={Spread.SPACE_BETWEEN} maxWidth="500px">
            <ImageList images={sponsorsLogos} desktopImageHeight="75px" mobileImageHeight="65px" displayTitle flex="auto" />
          </Row>
        )}

        <Media query={{ maxWidth: MediaQuery.RESOLUTION_768 }}>
          {matches => (
            <Row maxWidth={matches ? "100%" : "60%"}>
              {sponsorsLogos && <S.Divider />}
              {licensesLogos && (
                <Row horizontalSpread={Spread.SPACE_AROUND} horizontalChildGap={Gap.SMALL}>
                  <ImageList images={licensesLogos} desktopImageHeight="90px" mobileImageHeight="60px" />
                </Row>
              )}
            </Row>
          )}
        </Media>

        <S.Markdown $eighteenPlusIcon={get18PlusIcon()} rehypePlugins={[rehypeRaw]}>
          {footerDisclaimer.childMarkdownRemark.html}
        </S.Markdown>

        {singleRgLogo && (
          <a key={singleRgLogo.file.url} href={singleRgLogo.title || undefined} target="_blank" rel="noreferrer">
            <Picture src={singleRgLogo.file.url} useWebp>
              <S.Logo>
                <img src={singleRgLogo.file.url} alt={singleRgLogo.title} />
              </S.Logo>
            </Picture>
          </a>
        )}
        <Row horizontalSpread={Spread.CENTER} horizontalChildGap={Gap.MEDIUM}>
          {responsibleGamingLogos && <ImageList images={responsibleGamingLogos} desktopImageHeight="45px" mobileImageHeight="40px" />}
        </Row>
      </Column>
    </TemplateSection>
  )
}

export const defaultTypes = {
  language: PropTypes.string,
  sponsorsText: PropTypes.string,
  sponsorsLogos: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string.isRequired
    })
  ),
  licensesLogos: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string.isRequired
    })
  ),
  footerDisclaimer: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string
    })
  }),
  singleRgLogo: PropTypes.shape({
    title: PropTypes.string,
    file: PropTypes.shape({
      url: PropTypes.string
    })
  }),
  responsibleGamingLogos: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string.isRequired
    })
  ),

  background: PropTypes.string
}

export const defaultProps = {
  language: "row",
  licensesLogos: [],
  sponsorsText: null,
  sponsorsLogos: [],
  footerDisclaimer: null,
  singleRgLogo: null,
  responsibleGamingLogos: [],

  background: "#fff"
}

Footer.propTypes = defaultTypes
Footer.defaultProps = defaultProps

export default Footer
