import styled, { css } from "styled-components"
import { LazyLoadImage } from "react-lazy-load-image-component"

export const ScalingImage = styled(LazyLoadImage)`
  width: ${props => props.$width ?? "100%"};

  padding: ${props => props.$padding || "25px"};

  box-sizing: border-box;
`

export const hideScrollbars = css`
  // Edge
  -ms-overflow-style: none;

  // Firefox
  scrollbar-width: none;

  // Chrome & Safari
  ::-webkit-scrollbar {
    display: none;
  }
`
