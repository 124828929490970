import styled from "styled-components"
import { Res } from "../../utils/constants"

export const AwardsContainer = styled.section`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  max-width: ${Res._1024}px;

  padding: 50px 0;

  margin: 0 auto;
  background: #ededed;
`

export const Title = styled.h2`
  margin-bottom: 30px;
  color: #333333;
  font-size: 1.8rem;

  @media (min-width: ${Res._768}px) {
    font-size: 2.4rem;
  }
`

export const Awards = styled.div`
  display: flex;
  flex-wrap: wrap;

  justify-content: center;

  margin: 0 ${props => props.theme.boxLayout.margin.value};
`

export const ImageContainer = styled.div`
  margin: 10px;

  display: flex;
  justify-content: center;

  @media (max-width: ${Res._768}px) {
    max-width: 150px;
    max-height: 120px;

    img {
      width: 100%;
      height: auto;
      max-width: 190px;
      max-height: 120px;
    }
  }

  p {
    margin: 5px auto;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
  }
`
