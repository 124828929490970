import styled, { css } from "styled-components"

import constrictHorizontal from "../../styles/utils"
import { Padding } from "../../styles/constants"

export const TemplateSection = styled.section`
  position: relative;

  display: flex;
  justify-content: center;

  box-sizing: border-box;

  width: 100%;
  min-height: ${props => props.$minHeight};

  margin-top: ${props => props.$marginTop};

  ${props =>
    props.$hideOverflowX &&
    css`
      overflow-x: hidden;
    `}
  ${props =>
    props.$hideOverflowY &&
    css`
      overflow-y: hidden;
    `}

    ${props =>
    props.$hideOverflow &&
    css`
      overflow: hidden;
    `}

  ${props =>
    props.$topPadding &&
    props.$topPadding !== Padding.NONE &&
    css`
      padding-top: ${props.$topPadding};
    `};

  ${props =>
    props.$bottomPadding &&
    props.$bottomPadding !== Padding.NONE &&
    css`
      padding-bottom: ${props.$bottomPadding};
    `};

  ${props => css`
    background: ${props.$background};
  `};
`

export const Inner = styled.div`
  ${constrictHorizontal};
  background: transparent;

  display: flex;
  flex-direction: ${props => props.$flexDirection};
  @media (max-width: 768px) {
    flex-direction: column;
  }
  justify-content: center;

  width: 100%;
`
