import React from "react"
import PropTypes from "prop-types"

import * as S from "./style"

const LUFixedImage = ({ fixed: { srcSetWebp, srcSet, src, width, height }, title, description }) => (
  <S.Picture>
    <source srcSet={srcSetWebp} type="image/webp" />
    <source srcSet={srcSet} type="image/png" />
    <img loading="lazy" width={width} height={height} src={src} title={title} alt={description} />
  </S.Picture>
)

LUFixedImage.propTypes = {
  fixed: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string,
  description: PropTypes.string
}

LUFixedImage.defaultProps = {
  title: "",
  description: ""
}

export default LUFixedImage
