import React from "react"
import PropTypes from "prop-types"

import { Spread, Gap } from "../../styles/constants"

import * as S from "./style"

const Row = ({
  children,

  horizontalChildGap,
  horizontalSpread,
  maxWidth,
  minHeight,
  padding,
  fill,
  backgroundColour,
  isScrolling
}) => (
  <S.Row
    $horizontalChildGap={horizontalChildGap}
    $horizontalSpread={horizontalSpread}
    $maxWidth={maxWidth}
    $minHeight={minHeight}
    $padding={padding}
    $fill={fill}
    $backgroundColour={backgroundColour}
    $isScrolling={isScrolling}
  >
    {children}
  </S.Row>
)

export const defaultTypes = {
  children: PropTypes.node.isRequired,

  horizontalChildGap: PropTypes.oneOf(Object.values(Gap)),
  horizontalSpread: PropTypes.oneOf(Object.values(Spread)),
  maxWidth: PropTypes.string,
  minHeight: PropTypes.string,
  padding: PropTypes.string,
  fill: PropTypes.bool,
  backgroundColour: PropTypes.string,
  isScrolling: PropTypes.bool
}

export const defaultProps = {
  horizontalChildGap: Gap.NONE,
  horizontalSpread: Spread.FLEX_START,
  maxWidth: "none",
  minHeight: "none",
  padding: undefined,
  fill: false,
  backgroundColour: "transparent",
  isScrolling: false
}

Row.propTypes = defaultTypes
Row.defaultProps = defaultProps

export default Row
